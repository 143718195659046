<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} | IDM ${id}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1 && data.id" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                dark
                :color="
                  data.valid != null ? (data.valid ? 'success' : 'error') : ''
                "
                @click="validDialog"
              >
                <v-icon>
                  mdi-{{
                    data.valid != null
                      ? data.valid
                        ? "check-"
                        : "alert-"
                      : ""
                  }}decagram
                </v-icon>
              </v-btn>
            </template>
            <span
              v-text="
                data.valid != null
                  ? data.valid
                    ? 'Verificado'
                    : 'Duplicado'
                  : 'Sin validar'
              "
            />
          </v-tooltip>
          <v-tooltip v-if="data.user_id" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                dark
                color="yellow darken-3"
                @click="passUpdateDialog"
              >
                <v-icon> mdi-lock </v-icon>
              </v-btn>
            </template>
            <span v-text="'Cambiar contraseña'" />
          </v-tooltip>
          <v-tooltip v-if="revisions_data.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                color="pink darken-3"
                dark
                @click="revisions_dialog = true"
              >
                <v-icon> mdi-folder-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Historial de revisiones'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              login.role_id == 1 &&
              data.valid &&
              login.permissions.doctors_revisions.update &&
              data.doctor_revision
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                color="warning"
                @click="revDataDialog"
              >
                <v-icon> mdi-folder-edit </v-icon>
              </v-btn>
            </template>
            <span
              v-text="
                `Edición de información R${
                  data.doctor_revision.revision ? 2 : 1
                }`
              "
            />
          </v-tooltip>
          <v-tooltip v-if="data.doctor_revision_rejected" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                color="error"
                @click="rev_rejected_dialog = true"
              >
                <v-icon> mdi-folder-edit </v-icon>
              </v-btn>
            </template>
            <span v-text="'Edición de información rechazada'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.doctors.update && !data.doctor_revision"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col v-if="data.doctor_prerevision" cols="12">
          <v-alert type="warning" class="mb-0" dense>
            <span>
              Existe una pre revisión en proceso ({{
                data.doctor_prerevision.id
              }})
            </span>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="
                  `GENERAL${
                    data.created_by_service ? ' | CREADO DESDE SERVICIO' : ''
                  }`
                "
              />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Apellido paterno"
                    :value="data.first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Apellido materno"
                    :value="data.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Usuario sistema"
                    :value="data.user.active ? 'ACTIVO' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="RFC" :value="data.code_tax" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Correo electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Monto"
                    :value="numberFormat(data.consultation_amount)"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observación"
                    :value="data.observation"
                    :alert="data.observation ? true : false"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Archivo valid."
                    :value="data.url_validation ? null : '-'"
                  />
                  <v-btn
                    v-if="data.url_validation"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="url_documents + '/doctors/' + data.url_validation"
                    target="_blank"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'ESPECIALIDADES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-for="(v, i) in data.specialties" :key="i">
                  <v-row v-if="v.active">
                    <v-col cols="12" xs="12" md="4">
                      <ViewData :label="`Tipo (${i + 1})`" :value="v.name" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData label="Cédula" :value="v.license" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Cédula (PDF)"
                        :value="v.identification_card_pdf ? null : '-'"
                      />
                      <v-btn
                        v-if="v.identification_card_pdf"
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          v.identification_card_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONSULTORIOS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(v, i) in data.consulting_rooms"
                  :key="i"
                >
                  <v-row v-if="v.active">
                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <v-row>
                            <v-col cols="12">
                              <h2
                                class="text-caption"
                                v-text="`CONSULTORIO (${i + 1})`"
                              />
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="6">
                              <ViewData
                                label="Hospital"
                                :value="
                                  v.provider_id
                                    ? v.provider.trade_name
                                    : '*NINGUNO*'
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData label="Calle" :value="v.street" />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Número exterior"
                                :value="v.external_number"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Número interior"
                                :value="v.internal_number"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData label="Colonia" :value="v.suburb" />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Estado"
                                :value="
                                  v.entity_name
                                    ? v.entity_name
                                    : 'NO REGISTRADO'
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Municipio"
                                :value="
                                  v.municipality_name
                                    ? v.municipality_name
                                    : 'NO REGISTRADO'
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData label="Código postal" :value="v.zip" />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Comp. domicilio (PDF)"
                                :value="v.proof_address_pdf ? null : '-'"
                              />
                              <v-btn
                                v-if="v.proof_address_pdf"
                                x-small
                                fab
                                outlined
                                class="mr-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctors/' +
                                  v.proof_address_pdf
                                "
                                target="_blank"
                              >
                                <v-icon v-text="'mdi-download'" />
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'LEGAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Razón social" :value="data.legal_name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Código postal" :value="data.legal_zip" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Régimen fiscal"
                    :value="
                      data.legal_regime ? data.legal_regime.legal_regime : null
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Domicilio fiscal"
                    :value="data.legal_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="RFC (PDF)"
                    :value="data.code_tax_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.code_tax_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="url_documents + '/doctors/' + data.code_tax_pdf"
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Validar RFC"
                    :value="data.validate_code_tax ? 'ACTIVO' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONTACTO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Teléfono celular" :value="data.cell_phone" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'BANCO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Banco"
                    :value="data.bank_id ? data.bank.bank : 'NO REGISTRADO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Número de cuenta"
                    :value="data.account_number"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="CLABE" :value="data.clabe" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Cuenta bancaria (PDF)"
                    :value="data.bank_account_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.bank_account_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="url_documents + '/doctors/' + data.bank_account_pdf"
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Valid. cuenta (PDF)"
                    :value="data.account_validation_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.account_validation_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="
                      url_documents + '/doctors/' + data.account_validation_pdf
                    "
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Día de pago" :value="data.pay_day" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONSULTA'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nivel" :value="data.doctor_level_name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Costo efectivo"
                    :value="data.effective_cost ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Validado para alta"
                    :value="data.revision ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Confirmación de consulta"
                    :value="data.confirm_consultation ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Confirmar Servicio"
                    :value="data.service_confirm ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Autorizacion automática de médicamentos"
                    :value="data.medication_auth ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Generar carta servicio automáticamente"
                    :value="data.auto_letter_generated ? 'SI' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REDES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(doctor_in_group, i) in data.doctor_in_groups"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="12">
                      <ViewData
                        :label="`Nombre (${i + 1})`"
                        :value="doctor_in_group.doctor_group.doctor_group"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BtnCircle
            v-if="login.permissions.doctors.delete"
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
          <BtnCircle
            v-if="login.role_id == 1"
            tLabel="Asignar servicios"
            tPosition="top"
            icon="mdi-update"
            color="warning"
            size="x-small"
            :click="assignDialog"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="rev_data_dialog" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="rev_data_dialog_loading"
        :loading="rev_data_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            Edición de información R{{
              data.doctor_revision && data.doctor_revision.revision ? 2 : 1
            }}{{
              data.doctor_revision ? ` | ${data.doctor_revision.folio}` : ""
            }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rev_data_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && data.doctor_revision">
          <v-row>
            <v-col cols="12" />
            <v-col v-if="login.permissions.doctors_revisions.delete" cols="12">
              <v-btn
                x-small
                color="error"
                @click="doctorRevisionDelete(data.doctor_revision.id)"
              >
                Eliminar
                <v-icon right dark> mdi-delete-forever </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'GENERAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Teléfono "
                        :value="data.doctor_revision.phone"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Teléfono celular"
                        :value="data.doctor_revision.cell_phone"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'FISCAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Razón Social"
                        :value="data.doctor_revision.legal_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="RFC"
                        :value="data.doctor_revision.code_tax"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Código postal"
                        :value="data.doctor_revision.legal_zip"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Régimen"
                        :value="
                          data.doctor_revision.legal_regime
                            ? data.doctor_revision.legal_regime.legal_regime
                            : null
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Domicilio"
                        :value="data.doctor_revision.legal_address"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'BANCARIA'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Banco"
                        :value="
                          data.doctor_revision.bank_id
                            ? data.doctor_revision.bank.bank
                            : 'NO REGISTRADO'
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Número de cuenta"
                        :value="data.doctor_revision.account_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="CLABE "
                        :value="data.doctor_revision.clabe"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Cuenta bancaria (PDF)"
                        :value="
                          data.doctor_revision.bank_account_pdf &&
                          data.doctor_revision.bank_account_pdf != 'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          data.doctor_revision.bank_account_pdf &&
                          data.doctor_revision.bank_account_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          data.doctor_revision.bank_account_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Valid. cuenta (PDF)"
                        :value="
                          data.doctor_revision.account_validation_pdf &&
                          data.doctor_revision.account_validation_pdf != 'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          data.doctor_revision.account_validation_pdf &&
                          data.doctor_revision.account_validation_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          data.doctor_revision.account_validation_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ESPECIALIDAD(ES)'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    v-for="(specialty, i) in data.doctor_revision.specialties"
                    :key="i"
                    dense
                  >
                    <v-col cols="12" xs="12" md="5">
                      <ViewData
                        :label="`${i + 1}) Nombre`"
                        :value="specialty.specialty_type.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="5">
                      <ViewData label="Cédula" :value="specialty.license" />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <v-btn
                        v-if="
                          specialty.identification_card_pdf &&
                          specialty.identification_card_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          specialty.identification_card_pdf
                        "
                        target="_blank"
                      >
                        <v-icon> mdi-download </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'REGISTRO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Creación"
                        :value="data.doctor_revision.created_by.email"
                        :subvalue="data.doctor_revision.created_at"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="R1"
                        :value="
                          data.doctor_revision.revision_user &&
                          data.doctor_revision.revision_user.email
                        "
                        :subvalue="data.doctor_revision.revision_date"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="R2"
                        :value="
                          data.doctor_revision.confirmation_user &&
                          data.doctor_revision.confirmation_user.email
                        "
                        :subvalue="data.doctor_revision.confirmation_date"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              v-if="
                !doctor_revision_reject &&
                (!data.doctor_revision.revision ||
                  data.doctor_revision.revision_user_id != login.id)
              "
              cols="12"
            >
              <v-row>
                <v-col cols="6">
                  <v-btn
                    block
                    color="error"
                    @click="doctor_revision_reject = true"
                  >
                    Rechazar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    :color="
                      data.doctor_revision.revision ? 'success' : 'primary'
                    "
                    @click="revHandle(true)"
                  >
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="doctor_revision_reject" cols="12">
              <v-form
                v-on:submit.prevent
                ref="doctor_revision_form"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      label="Observación*"
                      v-model="doctor_revision_reject_observation"
                      :rules="rules.required"
                      rows="1"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="error"
                      dark
                      @click.prevent="revRejectVerify"
                      small
                    >
                      CONFIRMAR RECHAZO
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rev_rejected_dialog"
      scrollable
      persistent
      max-width="1800"
    >
      <v-card
        tile
        :disabled="rev_rejected_dialog_loading"
        :loading="rev_rejected_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            Edición de información
            {{
              data.doctor_revision_rejected
                ? ` | ${data.doctor_revision_rejected.folio}`
                : ""
            }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rev_rejected_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data.doctor_revision_rejected">
          <v-row>
            <v-col cols="12" />

            <v-col
              v-if="login.permissions.doctors_revisions.delete"
              cols="12"
              class="text-right"
            >
              <v-btn
                color="error"
                @click="doctorRevisionDelete(data.doctor_revision_rejected.id)"
              >
                Eliminar
                <v-icon right dark> mdi-delete-forever </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-alert type="error" class="mb-0" dense>
                <b>RECHAZADA</b>
                <br />
                <span v-text="data.doctor_revision_rejected.observation" />
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'GENERAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Teléfono "
                        :value="data.doctor_revision_rejected.phone"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Teléfono celular"
                        :value="data.doctor_revision_rejected.cell_phone"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'FISCAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Razón Social"
                        :value="data.doctor_revision_rejected.legal_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="RFC"
                        :value="data.doctor_revision_rejected.code_tax"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Código postal"
                        :value="data.doctor_revision_rejected.legal_zip"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Régimen"
                        :value="
                          data.doctor_revision_rejected.legal_regime
                            ? data.doctor_revision_rejected.legal_regime
                                .legal_regime
                            : null
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Domicilio"
                        :value="data.doctor_revision_rejected.legal_address"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'BANCARIA'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Banco"
                        :value="
                          data.doctor_revision_rejected.bank_id
                            ? data.doctor_revision_rejected.bank.bank
                            : 'NO REGISTRADO'
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Número de cuenta"
                        :value="data.doctor_revision_rejected.account_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="CLABE "
                        :value="data.doctor_revision_rejected.clabe"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Cuenta bancaria (PDF)"
                        :value="
                          data.doctor_revision_rejected.bank_account_pdf &&
                          data.doctor_revision_rejected.bank_account_pdf !=
                            'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          data.doctor_revision_rejected.bank_account_pdf &&
                          data.doctor_revision_rejected.bank_account_pdf !=
                            'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          data.doctor_revision_rejected.bank_account_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Valid. cuenta (PDF)"
                        :value="
                          data.doctor_revision_rejected
                            .account_validation_pdf &&
                          data.doctor_revision_rejected
                            .account_validation_pdf != 'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          data.doctor_revision_rejected
                            .account_validation_pdf &&
                          data.doctor_revision_rejected
                            .account_validation_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/doctors/' +
                          data.doctor_revision_rejected.account_validation_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ESPECIALIDAD(ES)'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    v-for="(specialty, i) in data.doctor_revision_rejected
                      .specialties"
                    :key="i"
                    dense
                  >
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        :label="`${i + 1}) Nombre`"
                        :value="specialty.specialty_type.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData label="Cédula" :value="specialty.license" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pass_update_dialog" persistent max-width="600">
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="passUpdateDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="pass_update_dialog">
          <v-container>
            <v-form v-on:submit.prevent ref="pass_update_form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Contraseña*"
                    v-model="pass_update.password"
                    :rules="rules.password"
                    :type="show_password ? 'text' : 'password'"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_password = !show_password"
                    autocomplete="new-password"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="warning" @click="passUpdate">
                    <v-icon v-text="'mdi-lock'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="revisions_dialog" scrollable persistent max-width="1800">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title> Historial de revisiones </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="revisions_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="revisions_data.length > 0">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12" v-for="(revision, i) in revisions_data" :key="i">
              <v-card class="mx-auto">
                <v-card-title>
                  <span
                    class="text-caption font-weight-bold"
                    v-text="
                      `${revisions_data.length - i}) REV. ${revision.folio} | ${
                        revision.accepted ? 'ACEPTADA' : 'RECHAZADA'
                      }`
                    "
                  />
                  <v-icon
                    class="ml-1"
                    :color="revision.accepted ? 'success' : 'error'"
                  >
                    {{ revision.accepted ? "mdi-check" : "mdi-cancel" }}
                  </v-icon>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2 class="text-caption" v-text="'GENERAL'" />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Teléfono "
                                :value="revision.phone"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Teléfono celular"
                                :value="revision.cell_phone"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2 class="text-caption" v-text="'FISCAL'" />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Razón Social"
                                :value="revision.legal_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="RFC"
                                :value="revision.code_tax"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Código postal"
                                :value="revision.legal_zip"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Régimen"
                                :value="
                                  revision.legal_regime
                                    ? revision.legal_regime.legal_regime
                                    : null
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Domicilio"
                                :value="revision.legal_address"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2 class="text-caption" v-text="'BANCARIA'" />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Banco"
                                :value="
                                  revision.bank_id
                                    ? revision.bank.bank
                                    : 'NO REGISTRADO'
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Número de cuenta"
                                :value="revision.account_number"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="CLABE "
                                :value="revision.clabe"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Cuenta bancaria (PDF)"
                                :value="
                                  revision.bank_account_pdf &&
                                  revision.bank_account_pdf != 'null'
                                    ? null
                                    : '-'
                                "
                              />
                              <v-btn
                                v-if="
                                  revision.bank_account_pdf &&
                                  revision.bank_account_pdf != 'null'
                                "
                                x-small
                                fab
                                outlined
                                class="mr-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctors/' +
                                  revision.bank_account_pdf
                                "
                                target="_blank"
                              >
                                <v-icon v-text="'mdi-download'" />
                              </v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Valid. cuenta (PDF)"
                                :value="
                                  revision.account_validation_pdf &&
                                  revision.account_validation_pdf != 'null'
                                    ? null
                                    : '-'
                                "
                              />
                              <v-btn
                                v-if="
                                  revision.account_validation_pdf &&
                                  revision.account_validation_pdf != 'null'
                                "
                                x-small
                                fab
                                outlined
                                class="mr-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctors/' +
                                  revision.account_validation_pdf
                                "
                                target="_blank"
                              >
                                <v-icon v-text="'mdi-download'" />
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2
                            class="text-caption"
                            v-text="'ESPECIALIDAD(ES)'"
                          />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row
                            v-for="(specialty, i) in revision.specialties"
                            :key="i"
                            dense
                          >
                            <v-col cols="12" xs="12" md="5">
                              <ViewData
                                :label="`${i + 1}) Nombre`"
                                :value="specialty.specialty_type.specialty_type"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="5">
                              <ViewData
                                label="Cédula"
                                :value="specialty.license"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="2">
                              <v-btn
                                v-if="
                                  specialty.identification_card_pdf &&
                                  specialty.identification_card_pdf != 'null'
                                "
                                x-small
                                fab
                                outlined
                                class="mr-1"
                                color="warning"
                                :href="
                                  url_documents +
                                  '/doctors/' +
                                  specialty.identification_card_pdf
                                "
                                target="_blank"
                              >
                                <v-icon> mdi-download </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2 class="text-caption" v-text="'REGISTRO'" />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="Creación"
                                :value="revision.created_by.email"
                                :subvalue="revision.created_at"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="R1"
                                :value="
                                  revision.revision_user &&
                                  revision.revision_user.email
                                "
                                :subvalue="revision.revision_date"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="4">
                              <ViewData
                                label="R2"
                                :value="
                                  revision.confirmation_user &&
                                  revision.confirmation_user.email
                                "
                                :subvalue="revision.confirmation_date"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assign_dialog" scrollable persistent max-width="1800">
      <v-card
        tile
        :loading="assign_dialog_loading"
        :disabled="assign_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Asignar servicios </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="assign_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="assign_dialog">
          <v-row>
            <v-col cols="12" />
            <v-col v-if="!compare" cols="12">
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <v-text-field
                    label="IDM*"
                    v-model="doctor_replaced_id"
                    type="text"
                    dense
                    counter
                    maxlength="9"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-btn block color="info" @click.prevent="compareData">
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12">
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="'MÉDICO OBJETIVO'" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'GENERAL'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    label="Nombre"
                                    :value="
                                      compare.target.doctor.id +
                                      ' | ' +
                                      compare.target.doctor.name +
                                      ' ' +
                                      compare.target.doctor.first_surname +
                                      ' ' +
                                      compare.target.doctor.second_surname
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Teléfono "
                                    :value="compare.target.doctor.phone"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Teléfono celular"
                                    :value="compare.target.doctor.cell_phone"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'LEGAL'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Razón Social"
                                    :value="compare.target.doctor.legal_name"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="RFC"
                                    :value="compare.target.doctor.code_tax"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Código postal"
                                    :value="compare.target.doctor.legal_zip"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Régimen"
                                    :value="
                                      compare.target.doctor.legal_regime
                                        ? compare.target.doctor.legal_regime
                                            .legal_regime
                                        : null
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Domicilio"
                                    :value="compare.target.doctor.legal_address"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'BANCO'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Banco"
                                    :value="
                                      compare.target.doctor.bank_id
                                        ? compare.target.doctor.bank.bank
                                        : 'NO REGISTRADO'
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Número de cuenta"
                                    :value="
                                      compare.target.doctor.account_number
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="CLABE "
                                    :value="compare.target.doctor.clabe"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Cuenta bancaria (PDF)"
                                    :value="
                                      compare.target.doctor.bank_account_pdf &&
                                      compare.target.doctor.bank_account_pdf !=
                                        'null'
                                        ? null
                                        : '-'
                                    "
                                  />
                                  <v-btn
                                    v-if="
                                      compare.target.doctor.bank_account_pdf &&
                                      compare.target.doctor.bank_account_pdf !=
                                        'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      compare.target.doctor.bank_account_pdf
                                    "
                                    target="_blank"
                                  >
                                    <v-icon v-text="'mdi-download'" />
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Valid. cuenta (PDF)"
                                    :value="
                                      compare.target.doctor
                                        .account_validation_pdf &&
                                      compare.target.doctor
                                        .account_validation_pdf != 'null'
                                        ? null
                                        : '-'
                                    "
                                  />
                                  <v-btn
                                    v-if="
                                      compare.target.doctor
                                        .account_validation_pdf &&
                                      compare.target.doctor
                                        .account_validation_pdf != 'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      compare.target.doctor
                                        .account_validation_pdf
                                    "
                                    target="_blank"
                                  >
                                    <v-icon v-text="'mdi-download'" />
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="'ESPECIALIDAD(ES)'"
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(specialty, i) in compare.target.doctor
                                  .specialties"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="5">
                                  <ViewData
                                    :label="`${i + 1}) Nombre`"
                                    :value="specialty.name"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="5">
                                  <ViewData
                                    label="Cédula"
                                    :value="specialty.license"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="2">
                                  <v-btn
                                    v-if="
                                      specialty.identification_card_pdf &&
                                      specialty.identification_card_pdf !=
                                        'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      specialty.identification_card_pdf
                                    "
                                    target="_blank"
                                  >
                                    <v-icon> mdi-download </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="'CONSULTORIOS'"
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(consulting_room, i) in compare.target
                                  .doctor.consulting_rooms"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    :label="`${i + 1}) Hospital | Consultorio`"
                                    :value="
                                      consulting_room.provider
                                        ? consulting_room.provider.trade_name
                                        : ''
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    label="Estado | Municipio"
                                    :value="
                                      consulting_room.entity_name +
                                      ' | ' +
                                      consulting_room.municipality_name
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="
                                  `SERVICIOS (${compare.target.rs_services.length})`
                                "
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(rs_service, i) in compare.target
                                  .rs_services"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    :label="`${i + 1}) Folio`"
                                    :value="rs_service.folio"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="F. creación"
                                    :value="rs_service.created_at"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Asegurado"
                                    :value="
                                      rs_service.enrollment +
                                      ' | ' +
                                      rs_service.name +
                                      ' ' +
                                      rs_service.first_surname +
                                      ' ' +
                                      rs_service.second_surname
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Contratante"
                                    :value="rs_service.contractor"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="'MÉDICO A DESACTIVAR'" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'GENERAL'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    label="Nombre"
                                    :value="
                                      compare.replaced.doctor.id +
                                      ' | ' +
                                      compare.replaced.doctor.name +
                                      ' ' +
                                      compare.replaced.doctor.first_surname +
                                      ' ' +
                                      compare.replaced.doctor.second_surname
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Teléfono "
                                    :value="compare.replaced.doctor.phone"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Teléfono celular"
                                    :value="compare.replaced.doctor.cell_phone"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'LEGAL'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Razón Social"
                                    :value="compare.replaced.doctor.legal_name"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="RFC"
                                    :value="compare.replaced.doctor.code_tax"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Código postal"
                                    :value="compare.replaced.doctor.legal_zip"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Régimen"
                                    :value="
                                      compare.replaced.doctor.legal_regime
                                        ? compare.replaced.doctor.legal_regime
                                            .legal_regime
                                        : null
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Domicilio"
                                    :value="
                                      compare.replaced.doctor.legal_address
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2 class="text-caption" v-text="'BANCO'" />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Banco"
                                    :value="
                                      compare.replaced.doctor.bank_id
                                        ? compare.replaced.doctor.bank.bank
                                        : 'NO REGISTRADO'
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Número de cuenta"
                                    :value="
                                      compare.replaced.doctor.account_number
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="CLABE "
                                    :value="compare.replaced.doctor.clabe"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Cuenta bancaria (PDF)"
                                    :value="
                                      compare.replaced.doctor
                                        .bank_account_pdf &&
                                      compare.replaced.doctor
                                        .bank_account_pdf != 'null'
                                        ? null
                                        : '-'
                                    "
                                  />
                                  <v-btn
                                    v-if="
                                      compare.replaced.doctor
                                        .bank_account_pdf &&
                                      compare.replaced.doctor
                                        .bank_account_pdf != 'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      compare.replaced.doctor.bank_account_pdf
                                    "
                                    replaced="_blank"
                                  >
                                    <v-icon v-text="'mdi-download'" />
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                  <ViewData
                                    label="Valid. cuenta (PDF)"
                                    :value="
                                      compare.replaced.doctor
                                        .account_validation_pdf &&
                                      compare.replaced.doctor
                                        .account_validation_pdf != 'null'
                                        ? null
                                        : '-'
                                    "
                                  />
                                  <v-btn
                                    v-if="
                                      compare.replaced.doctor
                                        .account_validation_pdf &&
                                      compare.replaced.doctor
                                        .account_validation_pdf != 'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      compare.replaced.doctor
                                        .account_validation_pdf
                                    "
                                    replaced="_blank"
                                  >
                                    <v-icon v-text="'mdi-download'" />
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="'ESPECIALIDAD(ES)'"
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(specialty, i) in compare.replaced.doctor
                                  .specialties"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="5">
                                  <ViewData
                                    :label="`${i + 1}) Nombre`"
                                    :value="specialty.name"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="5">
                                  <ViewData
                                    label="Cédula"
                                    :value="specialty.license"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="2">
                                  <v-btn
                                    v-if="
                                      specialty.identification_card_pdf &&
                                      specialty.identification_card_pdf !=
                                        'null'
                                    "
                                    x-small
                                    fab
                                    outlined
                                    class="mr-1"
                                    color="warning"
                                    :href="
                                      url_documents +
                                      '/doctors/' +
                                      specialty.identification_card_pdf
                                    "
                                    target="_blank"
                                  >
                                    <v-icon> mdi-download </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="'CONSULTORIOS'"
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(consulting_room, i) in compare.replaced
                                  .doctor.consulting_rooms"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    :label="`${i + 1}) Hospital | Consultorio`"
                                    :value="
                                      consulting_room.provider
                                        ? consulting_room.provider.trade_name
                                        : ''
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                  <ViewData
                                    label="Estado | Municipio"
                                    :value="
                                      consulting_room.entity_name +
                                      ' | ' +
                                      consulting_room.municipality_name
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <v-card class="mx-auto">
                            <v-card-title>
                              <h2
                                class="text-caption"
                                v-text="
                                  `SERVICIOS (${compare.replaced.rs_services.length})`
                                "
                              />
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row
                                v-for="(rs_service, i) in compare.replaced
                                  .rs_services"
                                :key="i"
                                dense
                              >
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    :label="`${i + 1}) Folio`"
                                    :value="rs_service.folio"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="F. creación"
                                    :value="rs_service.created_at"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Asegurado"
                                    :value="
                                      rs_service.enrollment +
                                      ' | ' +
                                      rs_service.name +
                                      ' ' +
                                      rs_service.first_surname +
                                      ' ' +
                                      rs_service.second_surname
                                    "
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                  <ViewData
                                    label="Contratante"
                                    :value="rs_service.contractor"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn color="warning" @click="replaceData"> ACEPTAR </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="valid_dialog" persistent max-width="600">
      <v-card
        tile
        :disabled="valid_dialog_loading"
        :loading="valid_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title> Validación </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="valid_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="valid_dialog">
          <v-container>
            <v-row dense>
              <v-col cols="12" xs="12" md="4">
                <v-btn small block color="" @click="validDialogHandle(null)">
                  <v-icon left> mdi-decagram </v-icon>
                  Sin validar
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-btn
                  small
                  block
                  color="error"
                  @click="validDialogHandle(false)"
                >
                  <v-icon left> mdi-alert-decagram </v-icon>
                  Duplicado
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-btn
                  small
                  block
                  color="success"
                  @click="validDialogHandle(true)"
                >
                  <v-icon left> mdi-check-decagram </v-icon>
                  Verificado
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { show, destroy } from "../../requests/pageRequest";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  rules,
  numberFormat,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      numberFormat: numberFormat,
      page_route: "doctors",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: { created_by: {}, updated_by: {} },
      url_documents: URL_DOCUMENTS,
      rev_data_dialog: false,
      rev_data_dialog_loading: false,
      pass_update: {},
      pass_update_dialog: false,
      show_password: false,
      rules: rules(),
      doctor_revision_reject: false,
      doctor_revision_reject_observation: null,
      rev_rejected_dialog: false,
      rev_rejected_dialog_loading: false,
      revisions_data: [],
      revisions_dialog: false,
      assign_dialog: false,
      assign_dialog_loading: false,
      doctor_replaced_id: null,
      compare: null,
      valid_dialog: false,
      valid_dialog_loading: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.revisions_data = [];

      show(this.page_route, this.login.token, this.id).then((resp) => {
        this.data = resp;

        Axios.get(
          URL_API + `/doctors/revision/history/${this.id}`,
          headersToken(this.login.token)
        ).then((resp) => {
          if (resp.data.success) {
            this.revisions_data = resp.data.data;
          } else {
            console.log(resp.data.message);
          }

          this.loading = false;
        });
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            destroy(
              this.page_route,
              this.login.token,
              this.id,
              this.login.id
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(resp.success ? "success" : "error", resp.message)
              );

              resp.success
                ? this.$router.push({ name: this.page_route })
                : console.log(resp.message);
            });
          }
        });
    },
    revDataDialog() {
      this.doctor_revision_reject = false;
      this.doctor_revision_reject_observation = null;
      this.rev_data_dialog = true;
    },
    revHandle(action) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma ${
              action ? "aceptar" : "rechazar"
            } la edición de información R${
              this.data.doctor_revision.revision ? 2 : 1
            }?`
          )
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.rev_data_dialog_loading = true;

            let api = `${URL_API}/doctors/revision/`;
            api += this.data.doctor_revision.revision
              ? "confirmation"
              : "revision";

            let obj = {
              id: this.data.doctor_revision.id,
              observation: this.doctor_revision_reject_observation,
            };

            if (this.data.doctor_revision.revision) {
              obj.accepted = action;
            } else {
              obj.accepted = action ? null : false;
            }

            Axios.post(api, obj, headersToken(this.login.token)).then(
              (resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.rev_data_dialog = false;
                  this.getData();
                } else {
                  console.log(resp.data.message);
                }

                this.rev_data_dialog_loading = false;
              }
            );
          }
        });
    },
    revRejectVerify() {
      if (this.$refs.doctor_revision_form.validate()) {
        this.revHandle(false);
      }
    },
    passUpdateDialog() {
      this.pass_update = {
        id: parseInt(this.data.user_id),
        password: "",
        updated_by_id: this.login.id,
      };

      this.pass_update_dialog = true;
    },
    passUpdateDialogClose() {
      this.pass_update_dialog = false;
      this.show_password = false;
      this.$refs.pass_update_form.resetValidation();
    },
    passUpdate() {
      if (this.$refs.pass_update_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma actualizar la contraseña?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/general/users/passupdate",
                this.pass_update,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.passUpdateDialogClose();
                  this.getData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    doctorRevisionDelete(doctor_revision_id) {
      this.$swal
        .fire(msgConfirm("Confirma eliminar la revisión?"))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.rev_data_dialog_loading = true;
            this.rev_rejected_dialog_loading = true;

            Axios.delete(
              `${URL_API}/doctors/revision/${doctor_revision_id}`,
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.rev_data_dialog = false;
                this.rev_rejected_dialog = false;
                this.getData();
              } else {
                console.log(resp.data.message);
              }

              this.rev_data_dialog_loading = false;
              this.rev_rejected_dialog_loading = false;
            });
          }
        });
    },
    assignDialog() {
      this.doctor_replaced_id = null;
      this.assign_dialog_loading = false;
      this.assign_dialog = true;
      this.compare = null;
    },
    compareData() {
      if (this.doctor_replaced_id != null && this.doctor_replaced_id != "") {
        this.assign_dialog_loading = true;

        Axios.get(
          `${URL_API}/doctors/${this.doctor_replaced_id}`,
          headersToken(this.login.token)
        ).then((resp) => {
          if (resp.data.success) {
            Axios.get(
              `${URL_API}/doctors/target/${this.id}/replace/${this.doctor_replaced_id}`,
              headersToken(this.login.token)
            ).then((resp) => {
              if (resp.data.success) {
                this.compare = resp.data.data;
              } else {
                console.log(resp.data.message);
              }

              this.assign_dialog_loading = false;
            });
          } else {
            this.$swal.fire(
              msgAlert("error", `IDM ${this.doctor_replaced_id} no encontrado`)
            );

            this.assign_dialog_loading = false;
          }
        });
      } else {
        this.$swal.fire(msgAlert("error", "Introduce un IDM"));
      }
    },
    replaceData() {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma la asignar servicios al IDM ${this.compare.target.doctor.id} del IDM ${this.compare.replaced.doctor.id}?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.assign_dialog_loading = true;

            Axios.post(
              URL_API + `/doctors/replace`,
              {
                doctor_target_id: this.compare.target.doctor.id,
                doctor_replaced_id: this.compare.replaced.doctor.id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.assign_dialog = false;
              } else {
                console.log(resp.data.message);
              }

              this.assign_dialog_loading = false;
            });
          }
        });
    },
    validDialog() {
      if (this.data.valid != true) {
        this.valid_dialog = true;
        this.valid_dialog_loading = false;
      }
    },
    validDialogHandle(valid) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma marcar como ${valid ? "valido" : "duplicado"} al médico?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.valid_dialog_loading = true;

            Axios.post(
              URL_API + `/doctors/valid`,
              {
                id: this.id,
                valid: valid,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.valid_dialog = false;
                this.getData();
              } else {
                console.log(resp.data.message);
              }

              this.valid_dialog_loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>